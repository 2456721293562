import React from 'react'

const BUTTON_BASE_CLASS = "rounded-md bg-indigo-600 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"

export default function Button(props) {
  const { variant } = props;

  const getVariantClass = () => {
    switch (variant) {

      case "secondary":
        return "bg-gray-600 hover:bg-gray-500";
      case "danger":
        return "bg-red-600 hover:bg-red-500";
      case "warning":
        return "bg-yellow-600 hover:bg-yellow-500";
      case "success":
        return "bg-green-600 hover:bg-green-500";
      case "primary":
      default:
        return "bg-indigo-600 hover:bg-indigo-500 disabled:bg-indigo-400 disabled:cursor-not-allowed";
    }
  }

  const getSizeClass = () => {
    switch (props.size) {
      case "sm":
        return "px-2 py-1 text-xs";
      case "lg":
        return "px-4 py-3 text-lg";
      case "md":
      default:
        return "px-3 py-2 text-md";
    }
  }
  return (
    <button className={getVariantClass() + " " + BUTTON_BASE_CLASS + " " + getSizeClass()}
      {...props} />
  )
}
