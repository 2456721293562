import Lottie from "lottie-react";
import LoginAnimation from '../../Animations/Login.json'
import React from 'react'

export default function Loader() {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div className='w-40'>
        <Lottie animationData={LoginAnimation} loop={true} />
      </div>
    </div>
  )
}
