import * as firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/storage';
import 'firebase/performance';
import 'firebase/auth';

let config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

var Firebase = firebase.initializeApp(config);
Firebase.performance();

export default Firebase;

