import clsx from 'clsx';
import firebase from 'firebase';
import moment from 'moment';
import { twMerge } from "tailwind-merge"

let cleanSnapArray = (snaps) => {
  let items = [];
  snaps.forEach(s => items.push({ id: s.id, ...s.data() }));
  return items;
}

let snapToData = s => ({ id: s.id, ...s.data() });

let getFirebaseTimestamp = () => firebase.firestore.FieldValue.serverTimestamp();

let getCurrentDate = () => {
  var today = new Date();
  return today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
}

const getIndexInArray = (value, key, array) => {
  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    if (element[key] === value) {
      return i;
    }
  }
  return -1;
};

const emojiPath = name => "/images/emoji/" + name + ".png";
const humanize = (value, metric) => moment.duration(value, metric).humanize();
const getCurrency = (user) => user.currency || "₹";
const humanizeCurrency = (value) => {
  // Add commas to the value based on indian value
  let parts = value.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

const cn = (...inputs) => {
  return twMerge(clsx(inputs))
}

export {
  cleanSnapArray,
  snapToData,
  getFirebaseTimestamp,
  getCurrentDate,
  getIndexInArray,
  emojiPath,
  humanize,
  getCurrency,
  humanizeCurrency,
  cn
}
