import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

import './Stylesheets/main.scss';
import * as Containers from './Containers';

import ErrorBoundary from './Components/Shared/ErrorBoundary';
import Authenticator from './Layouts/Authenticator';

class App extends Component {
  render() {
    return (
      <ErrorBoundary>
        <Router>
          <Routes>
            <Route path="/" element={<Authenticator><Containers.Home /></Authenticator>} />
            <Route exact path="/login" element={<Authenticator reverse={true}><Containers.Login /></Authenticator>} />
          </Routes>
        </Router>
      </ErrorBoundary>
    );
  }
}

export default App;
