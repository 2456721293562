import React from 'react';
import { Navigate } from 'react-router-dom';
import Firebase from '../Config/Firebase';
import Firestore from '../Config/Firestore';
import UserContext from '../Contexts/UserContext';
import Loader from '../Components/Shared/Loader';

export default class Authenticator extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      authenticated: false,
      errorMessage: "",
      status: null,
      newLink: null,
      user: null
    }
  }

  componentDidMount() {
    try {
      Firestore.enablePersistence({ experimentalTabSynchronization: true }).then(() => {
        this.authenticate();
      }).catch((err) => {
        this.authenticate();
      });
    } catch (e) {
      this.authenticate();
    }
  }

  cleanUser = (user) => {
    var userData = Object.assign({}, user);
    return {
      email: userData.email,
      name: userData.displayName,
      photo: userData.photoURL,
      uid: userData.uid,
      id: userData.uid,
      emailVerified: userData.emailVerified,
      createdAt: userData.metadata.creationTime,
      preference: {
        muteEmailOnAddedHabit: false,
        muteEmailOnMentionedInComment: false,
        muteEmailOnUpdatePublished: false,
        muteEmailOnNewComment: false
      }
    }
  }

  updateUserContext = (user) => this.setState({ user });

  updateLocalCurrentUser = (currentUser) => localStorage.setItem('currentUser', JSON.stringify(currentUser));

  storeUser = (user) => {
    var cleanedUser = this.cleanUser(user);
    var userRef = Firestore.collection("users").doc(cleanedUser.id);

    return userRef.get()
      .then(userSnap => (
        !userSnap.exists ? userRef.set(cleanedUser) : true
      ))
      .then(() => userRef.get())
      .then((userSnap) => {
        let data = userSnap.data();
        data.id = userSnap.id;
        this.updateLocalCurrentUser(data);
        return data;
      })
  }

  authenticate() {
    Firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        return this.storeUser(user)
          .then((storedUser) => {
            return this.setState({
              user: storedUser,
              loading: false,
              authenticated: true,
            })
          }).catch((err) => {
            this.setState({
              loading: false,
              authenticated: false,
              newLink: "/login"
            })
          });
      } else {
        this.setState({
          loading: false,
          authenticated: false,
          newLink: "/login"
        })
      }
    });
  }

  sendVerificationEmail = () => this.state.user.sendEmailVerification();

  renderVerificationBanner = () => {
    let { user } = this.state;
    if (user && !user.emailVerified) {
      return (
        <div className="alert alert-warning" role="alert">
          <div className="container flex-between">
            <span>
              Check your email and follow the instructions to complete the registration!
            </span>
            <button
              className="btn btn-warning btn-sm"
              onClick={this.sendVerificationEmail}>
              Resend Verification</button>
          </div>
        </div>
      )
    }
  }

  render() {
    let { loading, authenticated, user } = this.state;

    if (loading) {
      return (
        <div className="d-flex justify-content-center align-items-center min-vh-100 w-100">
          <div>
            <Loader />
          </div>
        </div>
      )
    }

    if (authenticated && this.props.reverse) {
      return (
        <Navigate to={{
          pathname: '/',
        }} />
      )
    } else if (!authenticated && !this.props.reverse) {
      return (
        <Navigate to={{
          pathname: '/login',
        }} />
      )
    }

    return (
      <UserContext.Provider value={({ ...user, updateUserContext: this.updateUserContext })}>
        {this.renderVerificationBanner()}
        {this.props.children}
      </UserContext.Provider>
    )
  }
}
