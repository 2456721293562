import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      errorMessage: null,
    }
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      errorMessage: error.message
    })
  }

  render() {
    let { hasError, errorMessage } = this.state;
    if (hasError) {
      // Fallback UI
      return (
        <div className="not-found-container">
          <div style={{ fontSize: "60px", fontWeight: 80 }}>{errorMessage}</div>
          <div>Please refresh the page or try again later.</div>
          <img src="/assets/images/error2.svg" height="300" alt="" />
        </div>
      )
    }
    return this.props.children;
  }
}

export default ErrorBoundary;