import React, { useState } from 'react'
import Firebase from '../Config/Firebase';
import * as firebase from 'firebase/app';
import Button from '../Components/Shared/Button';
import Lottie from "lottie-react";
import LoginAnimation from '../Animations/Login.json'

const Login = () => {
  const [loading, setLoading] = useState(false);

  const googleProvider = new firebase.auth.GoogleAuthProvider();

  const handleLogin = () => {
    var provider = googleProvider;
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    setLoading(true);
    Firebase.auth().signInWithPopup(provider)
      .catch((error) => {
        setLoading(false);
      })
  }

  return (
    <div className="text-center flex items-center flex-col h-screen justify-center gap-4">

      <div className='flex-1 flex items-center flex-col justify-center'>
        <h1 className='text-4xl font-regular'>Whats the worth?</h1>
        <p>
          <small className='text-gray-400'>A simple app to track how much you've spent on your product.</small>
        </p>
        <div className='w-40'>
          <Lottie animationData={LoginAnimation} loop={true} />
        </div>
        <Button onClick={handleLogin} disabled={loading}>
          {loading ? 'Waiting for login...' : 'Login with Google'}
        </Button>
      </div>
      <p className='text-sm text-gray-700 mb-5'>
        Built with ❤️ by <a href="https://twitter.com/zathvarun" target="_blank" rel="noopener noreferrer">@zathvarun</a>
      </p>
    </div>
  )
}

export default Login