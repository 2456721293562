import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from '../Shared/Dialog'
import React, { useRef, useState } from 'react'
import Button from '../Shared/Button'
import { createItem, processItem } from '../../Models/Item';
import Firebase from '../../Config/Firebase';
import Input from '../Shared/Input';
import { DialogHeader } from '../Shared/Dialog';


export default function AddProductDialog({ onAdd }) {

  const currentUser = Firebase.auth().currentUser;
  const [item, setItem] = useState({ userId: currentUser.uid });
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const formEl = useRef();
  const handleChange = (key, value) => {
    let newItem = { ...item };
    newItem[key] = value;
    setItem(newItem);
  }

  const create = (e) => {
    e.preventDefault();
    setLoading(true);
    createItem(item).then(snap => {
      let newItem = processItem({ id: snap.id, ...item });
      onAdd(newItem);
      setOpen(false);
    }).then(() => {
      setItem({ userId: currentUser.uid });
      formEl.current.reset();
    })
      .catch(err => console.log(err))
      .then(() => setLoading(false));
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button size="sm">Add Product</Button>
      </DialogTrigger>
      <DialogContent className="px-2">
        <DialogHeader>
          <DialogTitle>
            Add new product
          </DialogTitle>
          <DialogDescription>
            Add a product that you want to track.
          </DialogDescription>
        </DialogHeader>
        <div>
          <form className="flex flex-col gap-3 align-items-end" onSubmit={create} ref={formEl}>
            <div>
              <label htmlFor="product-name" className="form-label">Product Name *</label>
              <Input id="product-name" name="product-name" disabled={loading} type="text" onChange={(e) => handleChange('name', e.target.value)} placeholder="Name of the Item" required />
            </div>
            <div>
              <label htmlFor="purchase-date" className="form-label">Purchase Date *</label>
              <Input name="purchase-date" id="purchase-date" disabled={loading} type="date" onChange={(e) => handleChange('date', e.target.value)} placeholder="Date of Purchase" required />
            </div>
            <div>
              <label htmlFor="purchase-value" className="form-label">Purchase Value *</label>
              <Input name="purchase-value" id="purchase-value" step="0.01" disabled={loading} type="number" onChange={(e) => handleChange('price', parseFloat(e.target.value))} placeholder="Price" required />
            </div>
            <div>
              <label htmlFor="expected-daily-value" className="form-label">Expected Daily Value</label>
              <Input name="expected-daily-value" id="expected-daily-value" step="0.01" disabled={loading} type="number" onChange={(e) => handleChange('expectedDailyValue', parseFloat(e.target.value))} placeholder="Price" />
              <div id="expected-daily-value-help" className="text-gray-700">
                <small>Its the amount how much you want the product to cost to daily. Like if you feel satisfied that the product costs you only Rs.10/Day then thats the value you need to enter.</small>
              </div>
            </div>
            <div className='self-end'>
              <Button type="submit" size="md" disabled={loading}>
                {loading ? 'Adding...' : 'Add Product'}
              </Button>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  )
}
