import React, { useState, useRef } from 'react';
import Firebase from '../../Config/Firebase';
import { processItem, updateItem } from '../../Models/Item';
import Input from '../Shared/Input';
import Button from '../Shared/Button';

export default function ItemForm(props) {
  const currentUser = Firebase.auth().currentUser;
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(props.item);
  const formEl = useRef();

  const handleChange = (key, value) => {
    let newItem = { ...item };
    newItem[key] = value;
    setItem(newItem);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    updateItem(item.id, item)
      .then(snap => {
        let newItem = processItem(item);
        props.onUpdate(newItem);
      })
      .then(() => formEl.current.reset())
      .catch(err => console.log(err))
      .then(() => setLoading(false));
  }

  return (
    <form className="flex flex-col row gap-3 mt-1" onSubmit={handleSubmit} ref={formEl}>
      <div className="col-12">
        <label for="product-name" className="text-sm text-gray-600 font-medium">Product Name *</label>
        <Input value={item.name} id="product-name" name="product-name" disabled={loading} type="text" onChange={(e) => handleChange('name', e.target.value)} placeholder="Name of the Item" required />
      </div>
      <div className="col-12">
        <label for="purchase-date" className="text-sm text-gray-600 font-medium">Purchase Date *</label>
        <Input value={item.date} name="purchase-date" id="purchase-date" disabled={loading} type="date" onChange={(e) => handleChange('date', e.target.value)} placeholder="Date of Purchase" required />
      </div>
      <div className="col-12">
        <label for="purchase-value" className="text-sm text-gray-600 font-medium">Purchase Value *</label>
        <Input value={item.price} name="purchase-value" id="purchase-value" disabled={loading} type="number" onChange={(e) => handleChange('price', parseFloat(e.target.value))} placeholder="Price" required />
      </div>
      <div className="col-12">
        <label for="expected-daily-value" className="text-sm text-gray-600 font-medium">Expected Daily Value</label>
        <Input value={item.expectedDailyValue} name="expected-daily-value" id="expected-daily-value" disabled={loading} type="number" onChange={(e) => handleChange('expectedDailyValue', parseFloat(e.target.value))} placeholder="Price" />
      </div>
      <div className="col-12">
        <Button type="submit" disabled={loading}>Save</Button>
      </div>
    </form>
  )
}
