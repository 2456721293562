import React, { useContext, useState } from 'react';
import UserContext from '../../Contexts/UserContext';
import { getCurrency, humanize, humanizeCurrency } from '../../Lib/Utils';
import { deleteItem } from '../../Models/Item';
import ItemForm from './ItemForm';
import { TrashIcon, PencilIcon } from '@primer/octicons-react'

const Item = ({ edit = false, onDelete, ...rest }) => {
  const [item, setItem] = useState(rest.item);
  const user = useContext(UserContext);

  const [deleting, setDeleting] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const handleDelete = () => {
    setDeleting(true);
    deleteItem(item.id)
      .then(() => onDelete(item.id))
      .catch(err => console.log(err))
      .then(() => setDeleting(false));
  }

  const handleUpdate = (item) => {
    setItem(item);
    setShowForm(false);
  }

  const getProgressClass = () => {
    if (item.usagePercentage) {
      if (item.usagePercentage >= 0 && item.usagePercentage < 20) return "bg-danger";
      if (item.usagePercentage > 20 && item.usagePercentage < 50) return "bg-warning";
      if (item.usagePercentage > 50 && item.usagePercentage < 70) return "bg-info";
      if (item.usagePercentage > 70 && item.usagePercentage < 90) return "bg-primary";
      if (item.usagePercentage > 90) return "bg-success";
    }
  }

  return (
    <div className="col-span-1 bg-white rounded-lg p-4 border shadow mt-1">
      <h5 className="font-semibold mb-0">{item.name} <small><span className="bg-yellow-600 px-1.5 py-1 text-white rounded-lg">{getCurrency(user)} {humanizeCurrency(item.price)}</span></small></h5>
      <div>
        <small className="text-gray-700">Purchased and used for <strong>{humanize(item.daysCount, "days")}</strong></small>
        <p className="text-2xl my-2">{getCurrency(user)} {humanizeCurrency(item.perDayCost)}/Day</p>
        {item.expectedDailyValue && (
          <>
            {item.daysToGo === 0 && (
              <small className="text-gray-700"><em>You've used the product to it's fullest expected daily value of {getCurrency(user)} {item.expectedDailyValue} 🎉</em></small>
            )}
            {item.daysToGo > 0 && (
              <small className="text-gray-700"><em>Use for <strong>{humanize(item.daysToGo, "days")}</strong> to reach your expected daily value of {getCurrency(user)} {item.expectedDailyValue}</em></small>
            )}

            <div className="progress mt-1" style={{ height: '5px' }}>
              <div className={"progress-bar " + getProgressClass()} role="progressbar" style={{ width: item.usagePercentage + "%" }}></div>
            </div>
          </>
        )}
        <div>
        </div>
      </div>
      {(
        <div className="mt-2 self-end">
          <div className="flex items-center justify-end gap-2">
            <button className="text-sm text-indigo-600 hover:bg-gray-200 rounded-lg p-1 py-0.5" onClick={() => setShowForm(!showForm)}>
              <PencilIcon />
              &nbsp;
              Edit
            </button>
            <button disabled={deleting} className="text-sm text-red-600 hover:bg-gray-200 rounded-lg p-1 py-0.5" onClick={handleDelete}>
              <TrashIcon />&nbsp; Delete

            </button>
          </div>
          {showForm && (
            <ItemForm item={item} onUpdate={handleUpdate} />
          )}
        </div>
      )}
    </div>
  )
}

export default Item