/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { listMyItems } from '../Models/Item';
import CreateItem from '../Components/Items/CreateItem';
import Item from '../Components/Items/Item';
import { getCurrency, getIndexInArray, humanize, humanizeCurrency } from '../Lib/Utils';
import UserContext from '../Contexts/UserContext';

const Home = () => {
  const user = useContext(UserContext)
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [edit, setEdit] = useState(false);

  const handleEdit = () => setEdit(!edit);

  useEffect(() => {
    listMyItems(user.uid)
      .then(({ items, total }) => {
        setItems(items);
        setTotal(total);
      })
      .then(() => setLoading(false))
      .catch(err => console.log(err));
  }, []);

  const renderItem = (item, i) => <Item item={item} key={item.id} user={user} edit={edit} onDelete={onDelete} />

  const onAdd = (item) => {
    setItems((_items) => {
      _items.unshift(item);
      return _items
    })
    setTotal((total) => total + item.perDayCost)
  }

  const onDelete = (itemId) => {
    let newItems = [...items];
    let idx = getIndexInArray(itemId, 'id', newItems);
    if (idx > -1) {
      newItems.splice(idx, 1);
    }

    setItems(newItems);
  }

  return (
    <div className='px-2 flex flex-col gap-3'>
      <CreateItem onAdd={onAdd} toggleEdit={handleEdit} />
      <div className="border rounded-lg shadow bg-white p-4 mt-1 text-center">
        <strong className='font-semibold'><small>You are spending already</small></strong>
        <p className="text-4xl my-2">{getCurrency(user)} {humanizeCurrency(total)}/Day</p>
        <small className="text-gray-700"><em>Make sure this doesn't goes above your daily salary.</em></small>
      </div>
      <div className='grid grid-cols-1 gap-3 md:grid-cols-3'>
        {items.map(renderItem)}
      </div>
    </div>
  )
}

export default Home